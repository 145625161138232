import { CloudUploadOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Avatar, Badge, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Client, MqttClient } from 'devices/client/client.interface';
import { deleteClient } from 'devices/client/client.service';
import { ClientDetailBtn } from 'devices/client/components/Detail';
import {
  RegisterClientBtn,
  UnRegisterClientBtn,
} from 'devices/client/components/RegisterClientBtn';
import RegisterClientModal from 'devices/client/components/RegisterClientModal';
import { Device } from 'devices/device/devices.interface';
import { ConfigVariableBtn } from 'devices/tag/components/ConfigVariableBtn';
import { ProBreadcrumb } from 'pro';
import {
  CreateNavButton,
  DeleteButton,
  DetailNavButton,
  EditNavButton,
  RefreshButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteDevice, getDevices } from '../devices.service';
import { PositionBtn } from './PositionBtn';

const DeviceList: React.FC = () => {
  const [registerState, setRegisterState] = useState(false);
  const [deviceId, setDeviceId] = useState(-1);

  const { tableProps, refresh } = useAntdTable(getDevices, {
    //refreshOnWindowFocus: true,
  });

  const renderStatus = (
    client: Client | undefined,
    mqttClient: MqttClient | undefined,
    id: number
  ) => {
    if (client && mqttClient && mqttClient.connected) {
      return (
        <>
          <Badge
            status="success"
            text={
              <Link to={`${id}/clients/${client.clientId}`}>
                已连接{'(' + client.clientId + ')'}
              </Link>
            }
          />
        </>
      );
    }
    if (client) {
      return (
        <>
          <Badge status="default" text="离线中" />
          {'(' + client.clientId + ')'}
        </>
      );
    }
    return <>未关联</>;
  };

  const columns: ColumnsType<Device> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '设备名称',
      render: (_, { id, name, avatar, no }) => (
        <Link to={id.toString()}>
          <Space>
            <Avatar
              shape="square"
              size={56}
              icon={<CloudUploadOutlined />}
              src={avatar ? avatar.downloadUrl : ''}
            />
            {name + (no ? `(${no})` : '')}
          </Space>
        </Link>
      ),
    },
    {
      title: '规格型号',
      dataIndex: 'model',
    },
    {
      title: '热处理工艺',
      dataIndex: 'technological',
    },
    {
      title: '产量（公斤/小时）',
      dataIndex: 'output',
    },
    {
      title: '网关状态',
      render: (_, { client, mqttClient, id }) =>
        renderStatus(client, mqttClient, id),
    },
    {
      title: '报警状态',
      render: (_, { alarms }) =>
        alarms &&
        alarms.length > 0 &&
        alarms[alarms.length - 1].status === '报警中' ? (
          <Badge
            status="error"
            text={
              <Link to={`/al/alarms/${alarms[alarms.length - 1].id}`}>
                报警中
              </Link>
            }
          />
        ) : (
          <Badge status="default" text="无报警" />
        ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id, client, mqttClient }) => (
        <Space size="middle">
          <DetailNavButton id={id} />
          {client && mqttClient && mqttClient.connected && (
            <ClientDetailBtn deviceId={id} clientId={client.clientId} />
          )}
          <EditNavButton id={id} text="编辑资料" />
          <PositionBtn deviceId={id} />
          {client ? (
            <>
              <ConfigVariableBtn deviceId={id} />
              <UnRegisterClientBtn
                onDel={() => deleteClient(id, client.clientId).then(refresh)}
              />
            </>
          ) : (
            <RegisterClientBtn
              onClick={() => {
                setRegisterState(true);
                setDeviceId(id);
              }}
            />
          )}
          <DeleteButton onDel={() => deleteDevice(id).then(refresh)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">设备清单</h2>
          <Space>
            <CreateNavButton />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />

      {registerState && (
        <RegisterClientModal
          open={registerState}
          setOpen={setRegisterState}
          refresh={refresh}
          deviceId={deviceId}
        />
      )}
    </>
  );
};

export default DeviceList;
